import axios, { AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { SalesDataValidations } from './types/UploadResponse';
import { Permission, User } from './types/User';
import { Dealer } from './types/Dealer';
import { SalesDataPartition } from './types/SalesDataPartition';
import { SalesDataUpload } from './types/SalesDataUpload';
import { SalesDataMapping } from './types/SalesDataMapping';
import { AuthConfig } from './types/AuthConfig';
import { UserEdit } from './types/UserEdit';
import { AMCWorkflow, AMCWorkflowExecution, AMCWorkflowExecutionRequest } from './types/AMCData';
import { TradeInMarket } from './types/TradeInMarket';
import { TradeInOffer } from './types/TradeInOffer';
import { DealerOfferResult } from './types/DealerOfferResult';
import { ValuationReportSummary } from './types/ValuationReportSummary';
import { OfferSummaryDTO } from './types/OfferSummaryDTO';
import { AmcInstance, AwsAmcInstance, ProvisionDetails } from './types/AmcInstance';
import { AmcOwningEntity } from './types/AmcOwningEntity';
import { DealerOverview } from './types/DealerOverview';
import { JobStatus } from './types/JobStatus';
import { Dashboard } from './types/Dashboard';
import { MappedDataSummary } from './types/MappedDataSummary';
import { DealerImportSite, ImportSite } from './types/DealerImportSite';
import { ForecastingParameters } from './types/AdvertiserRequest';
import { Vertical } from './types/Vertical';
import { SasAccount } from './types/SasAccount';
import { DspAdvertiser, DspAudienceCategory, DspEntity, ListAudienceResult, DspAdGroupDict } from './types/DspEntity';
import { Agency } from './types/Agency';
import { AmcAttribution } from './types/AmcAttribution';
import { DealerExportSite, ExportSite } from './types/ExportSite';
import { Workflow } from './types/Workflow';
import { Asset, SizmekAd } from './types/Sizmek';
import { ReleaseNote } from './types/ReleaseNote';
import { BuildInfo } from './types/BuildInfo';
import { Code } from './types/Code';
import { AmcAudience } from './types/AmcAudiences';
import { AmcReachAndFrequency, AmcReachAndFrequencyCriteria } from './types/AmcReachAndFrequency';
import { AdvertiserDataSetSummary, DataSet } from './types/DataSet';
import {
    Campaign,
    CampaignChangeRequest,
    CampaignCriteria,
    CampaignFlight,
    CampaignStatusCount,
    CpmValue,
    GeoLocation,
    ValidateDspCampaignResponse,
    CampaignAdGroup,
    AdServerPlacement,
} from './types/Campaign';
import { OemOffer, OemOfferStatusCount } from './types/OemOffer';
import { GridRowId } from '@mui/x-data-grid';
import {
    AttributionPerformance,
    CampaignPerformanceCriteria,
    CampaignPerformanceListCriteria,
    PerformanceListCriteria,
    PerformanceMetric,
} from './types/CampaignPerformance';
import { AttributionStatus, PolkDealer, PolkDealerDealer, PolkDealerSearch } from './types/Polk';
import { AmcDataSet } from './types/AmcDataSet';
import { AdCreative } from './types/AmazonAds';
import { FtCampaign, FtPlacement } from './types/FtCampaign';
import { AdServerImportSource, FileInfo } from './types/S3';

axios.interceptors.request.use(function (request) {
    if (sessionStorage.getItem('token')) {
        request.headers.common.Authorization = 'Bearer ' + sessionStorage.getItem('token');
    }
    return request;
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            return Auth.currentSession()
                .then((session) => {
                    sessionStorage.setItem('token', session.getIdToken().getJwtToken());
                    error.config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                    return axios.request(error.config);
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        } else {
            return Promise.reject(error);
        }
    }
);

const BYPASS_WAIT_INDICATOR = { headers: { XBusy: 'false' } };
const CUSTOM_ERROR_HANDLER = { headers: { XCustomErrorHandler: 'true' } };

const ApiService = {
    getAppConfig: function (): Promise<AxiosResponse> {
        return axios.get<AuthConfig>('/api/auth/config');
    },

    getUiBuildConfig: function (): Promise<AxiosResponse<BuildInfo>> {
        return axios.get('/buildInfo.json');
    },

    getApiBuildConfig: function (): Promise<AxiosResponse> {
        return axios.get('/api/auth/buildInfo');
    },

    getCodes: function (codeGroup: string): Promise<AxiosResponse<Code[]>> {
        return axios.get('/api/code/' + codeGroup, BYPASS_WAIT_INDICATOR);
    },

    /**
     * OEM Offers (PureCars / Cartender Integration)
     */
    getOemOffers: function (offerStatus: string): Promise<AxiosResponse<OemOffer[]>> {
        return axios.get('/api/oemoffer?offerStatus=' + offerStatus);
    },

    getOemOfferStatusCounts: function (): Promise<AxiosResponse<OemOfferStatusCount[]>> {
        return axios.get('/api/oemoffer/statusCounts');
    },

    updateOfferStatus: function (status: string, oemOfferIds: GridRowId[]): Promise<AxiosResponse> {
        return axios.post('/api/oemoffer/status?status=' + status, oemOfferIds);
    },

    deleteOemOffers: function (oemOfferIds: GridRowId[]): Promise<AxiosResponse> {
        return axios.post('/api/oemoffer/delete', oemOfferIds);
    },

    importOemOffers: function (): Promise<AxiosResponse> {
        return axios.post('/api/oemoffer/importOemOffers');
    },

    exportOemOffers: function (): Promise<AxiosResponse> {
        return axios.post('/api/oemoffer/exportOemOffers');
    },

    processAttributionExport: function (formData: FormData): Promise<AxiosResponse> {
        return axios.post('/api/attribution/export', formData);
    },

    /**
     * CRM Data
     */
    uploadSalesData: function (formData: FormData): Promise<AxiosResponse> {
        return axios.post<SalesDataValidations>('/api/salesdata/upload', formData);
    },

    updateSalesDataUpload: function (salesDataUpload: SalesDataUpload): Promise<AxiosResponse> {
        return axios.put('/api/salesdata/upload', salesDataUpload);
    },

    getDataSets: function (): Promise<AxiosResponse<DataSet[]>> {
        return axios.get('/api/salesdata/datasets');
    },

    deleteSalesData: function (salesDataUploadId: number): Promise<AxiosResponse> {
        return axios.delete('/api/salesdata/' + salesDataUploadId);
    },

    downloadSalesData: function (salesDataUploadId: number): Promise<AxiosResponse> {
        return axios.get('/api/salesdata/download/' + salesDataUploadId);
    },

    getSalesDataPartitionsBySalesDataUpload: function (
        salesDataId: number | null | undefined
    ): Promise<AxiosResponse<SalesDataPartition[]>> {
        return axios.get('/api/salesDataPartition/', {
            params: {
                salesDataId: salesDataId,
            },
        });
    },

    getSalesDataUploadsByDealer: function (
        agencyId: number | null,
        dealerId: number | null | undefined
    ): Promise<AxiosResponse<SalesDataUpload[]>> {
        const dealerParm =
            dealerId != null ? 'dealerId=' + dealerId : '' + (agencyId != null ? '&agencyId=' + agencyId : '');
        return axios.get('/api/salesdata/uploadsByDealer?' + dealerParm);
    },

    getSalesDataMapping: function (salesDataUploadId: number): Promise<AxiosResponse<SalesDataMapping>> {
        return axios.get<SalesDataMapping>('/api/salesdata/mapping/' + salesDataUploadId);
    },

    updateSalesDataMapping: function (mapping: SalesDataMapping): Promise<AxiosResponse> {
        return axios.post('/api/salesdata/mapping', mapping);
    },

    getMappedDataSummary: function (
        salesDataUploadId: number,
        salesDataMapping: SalesDataMapping
    ): Promise<AxiosResponse<MappedDataSummary>> {
        return axios.post<MappedDataSummary>(
            '/api/salesdata/mapping/sampleMappedData/' + salesDataUploadId,
            salesDataMapping
        );
    },

    queueForAMC: function (salesDataUpload: SalesDataUpload): Promise<AxiosResponse> {
        return axios.post('/api/salesdata/queue/', salesDataUpload);
    },

    /**
     * Users
     */
    getCurrentUser: function (): Promise<AxiosResponse<User>> {
        return axios.get<User>('/api/users/current');
    },

    getUser: function (userId: number): Promise<AxiosResponse<User>> {
        return axios.get<User>('/api/users/user/' + userId);
    },

    updateUser: function (user: User): Promise<AxiosResponse<User>> {
        return axios.post<User>('/api/users/user', user);
    },

    toggleUserEnabled: function (user: User): Promise<AxiosResponse> {
        return axios.post('/api/users/user/toggleEnabled', user);
    },

    resetUserPassword(user: User): Promise<AxiosResponse> {
        return axios.post('/api/users/user/resetPassword', user);
    },

    getUsers: function (): Promise<AxiosResponse<User[]>> {
        return axios.get<User[]>('/api/users');
    },

    getUserEdit: function (userId: number): Promise<AxiosResponse<UserEdit>> {
        return axios.get('/api/users/useredit/' + userId);
    },

    /**
     * Permissions
     */
    getPermissions: function (userType: string): Promise<AxiosResponse<Permission[]>> {
        return axios.get('/api/users/permissions/' + userType);
    },

    /**
     * Dealers / Advertisers
     */
    getDealers: function (): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/dealers', BYPASS_WAIT_INDICATOR);
    },

    getDealersFtAdvertiserIdIsNotNull: function (): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/dealers/getDealersFtAdvertiserIdIsNotNull');
    },

    getDealersForAMCInstance: function (amcInstanceId: number): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/dealers/amcInstance/' + amcInstanceId);
    },

    getAttributionPerformanceList: function (
        performanceListCriteria: PerformanceListCriteria
    ): Promise<AxiosResponse<AttributionPerformance[]>> {
        return axios.post('/api/dealers/amcAttribution/list', performanceListCriteria, BYPASS_WAIT_INDICATOR);
    },

    getDealer: function (dealerId: number): Promise<AxiosResponse<Dealer>> {
        return axios.get('/api/dealers/' + dealerId);
    },

    getDefaultDealer: function (): Promise<AxiosResponse<Dealer>> {
        return axios.get('/api/dealers/defaultDealer');
    },

    getDealerByName: function (dealerName: string): Promise<AxiosResponse<Dealer>> {
        return axios.get('/api/dealers/name/' + dealerName);
    },

    isDuplicate: function (dealer: Dealer): Promise<AxiosResponse<Dealer[]>> {
        return axios.post('/api/dealers/isduplicate', dealer, BYPASS_WAIT_INDICATOR);
    },

    getDealersByDspAdvertiserId(dspAdvertiserId: string): Promise<AxiosResponse<Dealer[]>> {
        return axios.post('/api/dealers/dspAdvertiser/' + dspAdvertiserId);
    },

    getDspAdvertiser(dspEntityId: number, dspAdvertiserId: string): Promise<AxiosResponse<DspAdvertiser>> {
        return axios.get(
            '/api/dealers/dspAdvertiser?dspEntityId=' + dspEntityId + '&dspAdvertiserId=' + dspAdvertiserId,
            BYPASS_WAIT_INDICATOR
        );
    },

    getDealerOverview: function (dealerId: number): Promise<AxiosResponse<DealerOverview>> {
        return axios.get('/api/dealers/' + dealerId + '/overview');
    },

    updateDealer: function (dealer: Dealer): Promise<AxiosResponse<Dealer>> {
        return axios.post('/api/dealers/', dealer, CUSTOM_ERROR_HANDLER);
    },

    createSizmekAdvertiser: function (dealer: Dealer): Promise<AxiosResponse<Dealer>> {
        return axios.post('/api/dealers/sizmekAdvertiser', dealer);
    },

    getAutoExportCount(
        dealerId: number,
        operator: string,
        age: number,
        newUsedFilter: string,
        certifiedFilter: string,
        customLabelFilter: string
    ) {
        return axios.post(
            '/api/dealers/autoexportcount',
            {
                dealerId: dealerId,
                ageExportFilterOperator: operator,
                ageExportFilterValue: age,
                newUsedFilter: newUsedFilter,
                certifiedFilter: certifiedFilter,
                customLabelFilter: customLabelFilter,
            },
            BYPASS_WAIT_INDICATOR
        );
    },

    uploadDealerImage(formData: FormData) {
        return axios.post('/api/dealers/image', formData);
    },

    getAssignedDealers: function (userId?: number): Promise<AxiosResponse<Dealer[]>> {
        let url = '/api/salesdata/assignedDealers';
        if (userId) {
            url += '?userId=' + userId;
        }
        return axios.get<Dealer[]>(url, BYPASS_WAIT_INDICATOR);
    },

    getVerticals: function (): Promise<AxiosResponse<Vertical[]>> {
        return axios.get('/api/dealers/verticals');
    },

    getSasAccounts: function (): Promise<AxiosResponse<SasAccount[]>> {
        return axios.get('/api/dealers/sasAccounts');
    },

    getDspEntities: function (): Promise<AxiosResponse<DspEntity[]>> {
        return axios.get('/api/dealers/dspEntities');
    },

    /**
     * DSP Audiences
     */

    getDspAudiences(
        dealerId: number,
        categoryPath: string[],
        nextToken: string | null | undefined
    ): Promise<AxiosResponse<ListAudienceResult>> {
        return axios.post('/api/dspAudience/', {
            dealerId: dealerId,
            categoryPath: categoryPath,
            nextToken: nextToken,
        });
    },

    getDspAudienceCategories: function (
        dealerId: number,
        categoryPath: string[]
    ): Promise<AxiosResponse<DspAudienceCategory[]>> {
        return axios.post('/api/dspAudience/category', {
            dealerId: dealerId,
            categoryPath: categoryPath,
        });
    },

    /**
     * AMC Reach and Frequency
     */
    getAmcReachAndFrequencyList: function (
        criteria: AmcReachAndFrequencyCriteria
    ): Promise<AxiosResponse<AmcReachAndFrequency[]>> {
        return axios.post('/api/amcReachAndFrequency/list', criteria);
    },

    /**
     * AMC Instances
     */
    getAmcInstances: function (): Promise<AxiosResponse<AmcInstance[]>> {
        return axios.get('/api/amc/amcInstances');
    },

    getAwsAmcInstances: function (): Promise<AxiosResponse<AwsAmcInstance[]>> {
        return axios.get('/api/amc/awsAmcInstances');
    },

    updateAmcInstance: function (amcInstance: AmcInstance): Promise<AxiosResponse> {
        return axios.post('/api/amc/amcInstance', amcInstance);
    },

    deleteAmcInstance: function (amcInstanceId: number): Promise<AxiosResponse> {
        return axios.delete('/api/amc/amcInstance/' + amcInstanceId);
    },

    provisionAmcInstance: function (provisionDetails: ProvisionDetails): Promise<AxiosResponse<AmcInstance>> {
        return axios.post('/api/amc/amcInstance/provision', provisionDetails);
    },

    getAmcDataSets: function (amcInstanceId: number): Promise<AxiosResponse<AmcDataSet[]>> {
        return axios.get('/api/amc/amcInstance/' + amcInstanceId + '/datasets');
    },

    /**
     * AMC Audiences
     */
    getAmcAudiences: function (amcInstanceId: number): Promise<AxiosResponse<AmcAudience[]>> {
        return axios.get('/api/amc/amcInstance/' + amcInstanceId + '/audiences');
    },

    createAmcAudience: function (dealerId: number, dataSetId: string): Promise<AxiosResponse<AmcAudience>> {
        return axios.post('/api/amc/dealer/' + dealerId + '/dataset/' + dataSetId + '/audience');
    },

    getWorkflows: function (): Promise<AxiosResponse<Workflow[]>> {
        return axios.get('/api/workflow');
    },
    saveWorkflow: function (workflow: Workflow): Promise<AxiosResponse<Workflow>> {
        return axios.post('/api/workflow', workflow);
    },
    deleteWorkflow: function (workflow: Workflow): Promise<AxiosResponse> {
        return axios.delete('/api/workflow/' + workflow.id);
    },

    getAmcWorkflows: function (amcInstanceId: number): Promise<AxiosResponse<AMCWorkflow[]>> {
        return axios.get('/api/amc/' + amcInstanceId + '/workflows');
    },

    saveAmcWorkflow: function (amcInstanceId: number, workflow: AMCWorkflow, isNew: boolean): Promise<AxiosResponse> {
        return axios.put('/api/amc/' + amcInstanceId + '/workflow?isNew=' + isNew, workflow);
    },

    deleteAmcWorkflow: function (amcInstanceId: number, workflowId: string): Promise<AxiosResponse> {
        return axios.delete('/api/amc/' + amcInstanceId + '/workflow/' + workflowId);
    },

    runWorkflow: function (
        amcInstanceId: number,
        workflowRequest: AMCWorkflowExecutionRequest
    ): Promise<AxiosResponse<AMCWorkflowExecution>> {
        return axios.post<AMCWorkflowExecution>('/api/amc/' + amcInstanceId + '/workflow/run', workflowRequest);
    },

    getWorkflowExecutions: function (
        amcInstanceId: number,
        workflowExecutionId: string
    ): Promise<AxiosResponse<AMCWorkflowExecution[]>> {
        return axios.get<AMCWorkflowExecution[]>(
            '/api/amc/' + amcInstanceId + '/workflowExecutions/' + workflowExecutionId
        );
    },

    getWorkflowExecutionResultFile: function (
        amcInstanceId: number,
        workflowExecutionId: string
    ): Promise<AxiosResponse> {
        return axios.get('/api/amc/' + amcInstanceId + '/workflowExecutionResults/' + workflowExecutionId);
    },

    getDataSetDetails: function (dealerId: number, dataSetName: string): Promise<AxiosResponse> {
        return axios.get('/api/amc/dataSetDetails?dealerId=' + dealerId + '&dataSetName=' + dataSetName);
    },

    getDataSetSummary: function (
        agencyId: number | null,
        dealerId: number | null | undefined
    ): Promise<AxiosResponse<AdvertiserDataSetSummary[]>> {
        return axios.get(
            '/api/amc/dataSetSummary?agencyId=' +
                (agencyId ? agencyId : '') +
                '&dealerId=' +
                (dealerId ? dealerId : ''),
            BYPASS_WAIT_INDICATOR
        );
    },

    getAdvertiserDataSetSummary: function (dealerId: number): Promise<AxiosResponse<AdvertiserDataSetSummary[]>> {
        return axios.get('/api/amc/dataSetSummary/dealer/' + dealerId, BYPASS_WAIT_INDICATOR);
    },

    /**
     * AMC Owning Entities
     */
    getAmcOwningEntities: function (): Promise<AxiosResponse<AmcOwningEntity[]>> {
        return axios.get('/api/amcOwningEntities');
    },

    updateAmcOwningEntity: function (amcOwningEntity: AmcOwningEntity): Promise<AxiosResponse> {
        return axios.post('/api/amcOwningEntities', amcOwningEntity);
    },

    deleteAmcOwningEntity: function (amcOwningEntityId: number): Promise<AxiosResponse> {
        return axios.delete('/api/amcOwningEntities/' + amcOwningEntityId);
    },

    /**
     * AMC Offline Attribution
     */
    getAmcAttributions: function (
        agencyId: number | null,
        dealerId: number | null | undefined
    ): Promise<AxiosResponse<AmcAttribution[]>> {
        const dealerParm = 'dealerId=' + (dealerId ? dealerId : '') + '&agencyId=' + (agencyId ? agencyId : '');
        return axios.get('/api/amcAttribution?' + dealerParm);
    },

    getAmcAttribution: function (id: number): Promise<AxiosResponse<AmcAttribution>> {
        return axios.get('/api/amcAttribution/' + id);
    },

    updateAmcAttribution(amcAttribution: AmcAttribution): Promise<AxiosResponse<AmcAttribution>> {
        return axios.post('/api/amcAttribution', amcAttribution);
    },

    deleteAmcAttribution(id: number): Promise<AxiosResponse> {
        return axios.delete('/api/amcAttribution/' + id);
    },

    /**
     * Trade-Ins / Offers
     */
    getTradeInMarkets: function (): Promise<AxiosResponse<TradeInMarket[]>> {
        return axios.get('/api/dealers/tradeInMarkets');
    },

    getOffers: function (dealerId: number): Promise<AxiosResponse<DealerOfferResult>> {
        return axios.get('/api/offers/dealer/' + dealerId);
    },

    uploadOffers: function (dealerId: number, formData: FormData): Promise<AxiosResponse> {
        return axios.post('/api/offers/dealer/' + dealerId, formData);
    },

    updateOffer: function (offer: TradeInOffer): Promise<AxiosResponse> {
        return axios.post('/api/offers/offer/' + offer.id, offer);
    },

    deleteOffer: function (dealerId: number, offer: TradeInOffer): Promise<AxiosResponse> {
        return axios.delete('/api/offers/dealer/' + dealerId + '/offer/' + offer.id);
    },

    runValuationReport: function (mode: string): Promise<AxiosResponse<ValuationReportSummary>> {
        return axios.get('/api/offers/valuationReport/' + mode);
    },

    runOfferExport: function (): Promise<AxiosResponse> {
        return axios.get('/api/offers/export');
    },

    getOfferSummary: function (dealerId: number): Promise<AxiosResponse<OfferSummaryDTO>> {
        return axios.get('/api/offers/dealer/' + dealerId + '/offerSummary');
    },

    /**
     * Admin calls
     */
    // Job Status Api calls
    getFailedJobCount: function (): Promise<AxiosResponse<number>> {
        return axios.get('/api/admin/jobStatus/failedJobCount', BYPASS_WAIT_INDICATOR);
    },

    getJobStatus: function (): Promise<AxiosResponse<JobStatus[]>> {
        return axios.get('/api/admin/jobStatus', BYPASS_WAIT_INDICATOR);
    },

    acknowledgeFailedJob: function (jobId: number): Promise<AxiosResponse> {
        return axios.post('/api/admin/jobStatus/acknowledge/' + jobId, {}, BYPASS_WAIT_INDICATOR);
    },

    // Release Notes
    getReleaseNotes: function (): Promise<AxiosResponse<ReleaseNote[]>> {
        return axios.get('/api/admin/releaseNotes', BYPASS_WAIT_INDICATOR);
    },

    getReleaseNote: function (id: number): Promise<AxiosResponse<ReleaseNote>> {
        return axios.get('/api/admin/releaseNotes/' + id, BYPASS_WAIT_INDICATOR);
    },

    getLastPublishedReleaseNote: function (): Promise<AxiosResponse<ReleaseNote | null>> {
        return axios.get('/api/admin/releaseNotes/lastPublished');
    },

    updateReleaseNote: function (releaseNote: ReleaseNote): Promise<AxiosResponse<ReleaseNote>> {
        return axios.post('/api/admin/releaseNotes', releaseNote);
    },

    deleteReleaseNote: function (id: number): Promise<AxiosResponse> {
        return axios.delete('/api/admin/releaseNotes/' + id);
    },

    getQuickSightDashboards: function (): Promise<AxiosResponse<Dashboard[]>> {
        return axios.get('/api/quicksight/dashboards');
    },

    getQuickSightDashboard: function (): Promise<AxiosResponse<Dashboard>> {
        return axios.get('/api/quicksight/dashboard', BYPASS_WAIT_INDICATOR);
    },

    updateDefaultDashboard: function (dashboardId: string | null): Promise<AxiosResponse> {
        return axios.post('/api/quicksight/dashboard/' + dashboardId);
    },

    getQuickSightDashboardUrl: function (dashboardId: string): Promise<AxiosResponse<string>> {
        return axios.get('/api/quicksight/dashboard/' + dashboardId, BYPASS_WAIT_INDICATOR);
    },

    getAnalyticsDocs: function (): Promise<AxiosResponse> {
        return axios.get('/api/analytics/apidoc');
    },

    /**
     * Import Sites, Dealer Import Sites
     */
    getAvailableImportSites: function (dealerId: number): Promise<AxiosResponse<ImportSite[]>> {
        return axios.get('/api/importSites/dealer/' + dealerId);
    },

    getImportSitesForDealer: function (dealerId: number): Promise<AxiosResponse<DealerImportSite[]>> {
        return axios.get('/api/importSites/dealerImportSites/dealer/' + dealerId);
    },

    updateDealerImportSite: function (dealerImportSite: DealerImportSite): Promise<AxiosResponse> {
        return axios.post('/api/importSites/dealerImportSite/', dealerImportSite);
    },

    deleteDealerImportSite: function (dealerId: number, importSiteId: number): Promise<AxiosResponse> {
        return axios.delete('/api/importSites/dealer/' + dealerId + '/importSite/' + importSiteId);
    },

    getDealerImportSites: function (): Promise<AxiosResponse<DealerImportSite[]>> {
        return axios.get('/api/importSites/dealerImportSites');
    },

    /**
     * Export Sites, Dealer Export Sites
     */
    getAvailableExportSites: function (dealerId: number): Promise<AxiosResponse<ExportSite[]>> {
        return axios.get('/api/exportSites/dealer/' + dealerId);
    },

    getExportSitesForDealer: function (dealerId: number): Promise<AxiosResponse<DealerExportSite[]>> {
        return axios.get('/api/exportSites/dealerExportSites/dealer/' + dealerId);
    },

    getDealerExportSites: function (): Promise<AxiosResponse<DealerExportSite[]>> {
        return axios.get('/api/exportSites/dealerExportSites');
    },

    updateDealerExportSite: function (dealerExportSite: DealerExportSite): Promise<AxiosResponse> {
        return axios.post('/api/exportSites/dealerExportSite/', dealerExportSite);
    },

    getExportData: function (dealerId: number, exportSiteId: number): Promise<AxiosResponse> {
        return axios.get('/api/exportSites/dealer/' + dealerId + '/exportSite/' + exportSiteId);
    },

    deleteExportData: function (dealerId: number, exportSiteId: number): Promise<AxiosResponse> {
        return axios.delete('/api/exportSites/dealer/' + dealerId + '/exportSite/' + exportSiteId);
    },

    getForecastingParameters: function (): Promise<AxiosResponse<ForecastingParameters>> {
        return axios.get('/api/dealerRequest/forecastingParameters');
    },

    /**
     * Agencies
     */
    getAgencies: function (): Promise<AxiosResponse> {
        return axios.get('/api/agency', BYPASS_WAIT_INDICATOR);
    },

    getAgency: function (id: number): Promise<AxiosResponse<Agency>> {
        return axios.get('/api/agency/' + id);
    },

    getAgencyForUser: function (): Promise<AxiosResponse<Agency>> {
        return axios.get('/api/agency/currentuser');
    },

    updateAgency(agency: Agency): Promise<AxiosResponse> {
        return axios.post('/api/agency', agency);
    },

    uploadAgencyImage(formData: FormData) {
        return axios.post('/api/agency/image', formData);
    },

    getAgencyAvailableDealers: function (id: number): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/agency/' + id + '/availabledealers');
    },

    getAgencyDealers: function (id: number): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/agency/' + id + '/dealers');
    },

    getAgencyByHost: function (host: string): Promise<AxiosResponse<Agency>> {
        return axios.get('/api/agency/host/' + host);
    },

    /**
     * Campaigns (replacement for CampaignRequest / CampaignOrder - 4.0
     */
    getCampaigns: function (campaignCriteria: CampaignCriteria): Promise<AxiosResponse<Campaign[]>> {
        return axios.post('/api/campaign/search', campaignCriteria);
    },

    getCampaignsForDealer: function (dealerId: number): Promise<AxiosResponse<Campaign[]>> {
        return axios.get('/api/campaign/dealer/' + dealerId, BYPASS_WAIT_INDICATOR);
    },

    getCampaign: function (campaignId: number): Promise<AxiosResponse<Campaign>> {
        return axios.get('/api/campaign/' + campaignId);
    },

    deleteCampaign(campaignId: number): Promise<AxiosResponse> {
        return axios.delete('/api/campaign/' + campaignId);
    },

    archiveCampaign(campaignId: number): Promise<AxiosResponse> {
        return axios.get('/api/campaign/' + campaignId + '/archive');
    },

    updateFlight: function (campaignFlight: CampaignFlight): Promise<AxiosResponse> {
        return axios.post('/api/campaign/flight', campaignFlight);
    },

    createFlight: function (
        campaignId: number,
        flightData: Pick<CampaignFlight, 'startDateTime' | 'endDateTime' | 'budgetAmount'>
    ): Promise<AxiosResponse> {
        return axios.post(`/api/campaign/${campaignId}/flight/create`, flightData);
    },

    createGatewayFlight: function (flightId: number): Promise<AxiosResponse> {
        return axios.get(`/api/gateway/flight/${flightId}/create`);
    },

    refreshDspStatus: function (campaignId: number): Promise<AxiosResponse<Campaign>> {
        return axios.post('/api/campaign/refreshDspStatus/' + campaignId);
    },

    updateCampaign: function (campaign: Campaign, files?: File[]): Promise<AxiosResponse<Campaign>> {
        const formData = new FormData();
        formData.append('campaignJson', JSON.stringify(campaign));
        const MAX_FILE_SIZE = 314572800;
        if (files) {
            files.forEach((file) => {
                if (file.size < MAX_FILE_SIZE) {
                    formData.append('file', file, file.name);
                }
            });
        }

        return axios.post('/api/campaign', formData);
    },

    getCampaignCreatives: function (id: number, changeRequestId?: number): Promise<AxiosResponse<string[]>> {
        return axios.get(
            '/api/campaign/id/' + id + '/creatives' + (changeRequestId ? '?changeRequestId=' + changeRequestId : ''),
            BYPASS_WAIT_INDICATOR
        );
    },

    getCampaignCreative: function (id: number, fileName: string): Promise<AxiosResponse> {
        return axios.post(
            '/api/campaign/creative',
            { id: id, fileName: encodeURI(fileName) },
            { responseType: 'blob' }
        );
    },

    findDuplicateCampaigns: function (
        currentCampaignId: number | null,
        dealerId?: number,
        mediaType?: string,
        campaignType?: string,
        strategyType?: string
    ): Promise<AxiosResponse<Campaign[]>> {
        return axios.get(
            '/api/campaign/duplicates' +
                '?dealerId=' +
                dealerId +
                '&mediaType=' +
                mediaType +
                '&campaignType=' +
                campaignType +
                '&strategyType=' +
                strategyType +
                (currentCampaignId ? '&currentCampaignId=' + currentCampaignId : '')
        );
    },

    validateDspCampaign: function (
        dealerId: number,
        dspCampaignId: string
    ): Promise<AxiosResponse<ValidateDspCampaignResponse>> {
        return axios.get('/api/campaign/dspCampaign?dealerId=' + dealerId + '&dspCampaignId=' + dspCampaignId);
    },

    getCampaignStatusCounts(campaignCriteria: CampaignCriteria): Promise<AxiosResponse<CampaignStatusCount[]>> {
        return axios.post('/api/campaign/statusCounts', campaignCriteria);
    },

    createAdServerPlacement: function (
        campaignId: number,
        connectedFtPlacement: Pick<
            AdServerPlacement,
            | 'fileNameKey'
            | 'libraryId'
            | 'adServerCampaignId'
            | 'adServerCampaignName'
            | 'placementId'
            | 'placementName'
            | 'fileName'
        >
    ): Promise<AxiosResponse<AdServerPlacement>> {
        return axios.post(`/api/adServer/${campaignId}/createAdServerPlacement`, connectedFtPlacement);
    },

    deleteAdServerPlacement: function (adServerPlacementId: number): Promise<AxiosResponse> {
        return axios.delete(`/api/adServer/deleteAdServerPlacement/${adServerPlacementId}`);
    },

    uploadAdServerCreative: function (
        importSourceId: number,
        adServerPlacementId: number
    ): Promise<AxiosResponse<AdServerPlacement>> {
        return axios.get(`/api/adServer/uploadAdServerCreative/${importSourceId}/${adServerPlacementId}`);
    },

    tryToApproveCreative: function (adServerPlacementId: number): Promise<AxiosResponse<AdServerPlacement>> {
        return axios.get(`/api/adServer/tryToApproveCreative/${adServerPlacementId}`);
    },

    replacePlacementCreative: function (adServerPlacementId: number): Promise<AxiosResponse<AdServerPlacement>> {
        return axios.get(`/api/adServer/replacePlacementCreative/${adServerPlacementId}`);
    },

    getGeoLocations: function (campaignId: number): Promise<AxiosResponse<GeoLocation[]>> {
        return axios.get(`/api/campaign/${campaignId}/geoLocations`, BYPASS_WAIT_INDICATOR);
    },

    getCampaignChangeRequestPending: function (campaignId: number): Promise<AxiosResponse<CampaignChangeRequest>> {
        return axios.get('/api/campaign/' + campaignId + '/campaignChangeRequestPending');
    },

    getCampaignChangeRequests: function (campaignId: number): Promise<AxiosResponse<CampaignChangeRequest[]>> {
        return axios.get('/api/campaign/' + campaignId + '/campaignChangeRequests');
    },

    getCampaignChangeRequest: function (
        campaignId: number,
        changeRequestId: number | null
    ): Promise<AxiosResponse<CampaignChangeRequest>> {
        return axios.get(
            '/api/campaign/' +
                campaignId +
                '/campaignChangeRequest' +
                (changeRequestId ? '?changeRequestId=' + changeRequestId : '')
        );
    },

    updateCampaignChangeRequest: function (
        campaignChangeRequest: CampaignChangeRequest,
        files: File[]
    ): Promise<AxiosResponse<CampaignChangeRequest>> {
        const formData = new FormData();
        formData.append('campaignJson', JSON.stringify(campaignChangeRequest));
        const MAX_FILE_SIZE = 314572800;
        if (files) {
            files.forEach((file) => {
                if (file.size < MAX_FILE_SIZE) {
                    formData.append('file', file, file.name);
                }
            });
        }

        return axios.post('/api/campaign/campaignChangeRequest', formData);
    },

    updateCampaignChangeRequestComplete: function (campaignId: number): Promise<AxiosResponse<Campaign>> {
        return axios.post('/api/campaign/' + campaignId + '/campaignChangeRequest/complete');
    },

    getCampaignPerformance: function (
        campaignPerformanceCriteria: CampaignPerformanceCriteria
    ): Promise<AxiosResponse<PerformanceMetric[]>> {
        return axios.post('/api/campaignPerformance', campaignPerformanceCriteria, BYPASS_WAIT_INDICATOR);
    },

    getCampaignPerformanceList: function (
        campaignPerformanceListCriteria: CampaignPerformanceListCriteria
    ): Promise<AxiosResponse<PerformanceMetric[]>> {
        return axios.post('/api/campaignPerformance/list', campaignPerformanceListCriteria, BYPASS_WAIT_INDICATOR);
    },

    getCampaignCPMValues: function (dealerId: number): Promise<AxiosResponse<CpmValue[]>> {
        return axios.get('/api/campaign/' + dealerId + '/cpm');
    },

    getCampaignAdGroups: function (dealerId: number): Promise<AxiosResponse<CampaignAdGroup[]>> {
        return axios.get('/api/campaign/getCampaignAdGroups/' + dealerId);
    },

    getOldestStartDateOfAdGroup: function (dealerId: number): Promise<AxiosResponse<string>> {
        return axios.get('/api/campaign/getOldestStartDateOfAdGroup?dealerId=' + dealerId);
    },

    getCampaignAdGroupsByDealerAfterDate: function (
        dealerId: number,
        yearMonth: string
    ): Promise<AxiosResponse<CampaignAdGroup[]>> {
        return axios.get(
            '/api/campaign/getCampaignAdGroupsByDealerAfterDate?dealerId=' + dealerId + '&yearMonth=' + yearMonth
        );
    },

    /**
     * Home Page Dashboard
     */

    getDealersForUser: function (): Promise<AxiosResponse<Dealer[]>> {
        return axios.get('/api/home/dealers', BYPASS_WAIT_INDICATOR);
    },

    getDealerAds: function (dealerId: number): Promise<AxiosResponse<SizmekAd[]>> {
        return axios.get('/api/dealers/' + dealerId + '/ads', BYPASS_WAIT_INDICATOR);
    },

    getDealerDspAdCreatives: function (dealerId: number): Promise<AxiosResponse<AdCreative[]>> {
        return axios.get('/api/dealers/' + dealerId + '/dspAdCreatives', BYPASS_WAIT_INDICATOR);
    },

    getDealerDspAsset: function (dealerId: number, assetId: string): Promise<AxiosResponse<AdCreative[]>> {
        return axios.get(`/api/dealers/${dealerId}/dspAsset/${assetId}`, BYPASS_WAIT_INDICATOR);
    },

    getAsset: function (dealerId: number, assetId: number): Promise<AxiosResponse<Asset>> {
        return axios.get('/api/dealers/' + dealerId + '/asset/' + assetId);
    },

    getAdServerImportSources: function (): Promise<AxiosResponse<AdServerImportSource[]>> {
        return axios.get('/api/adServer/getImportSources');
    },

    getAdServerIncomingS3Files: function (
        importSourceId: number,
        extention: string
    ): Promise<AxiosResponse<FileInfo[]>> {
        return axios.get(`/api/adServer/getIncomingS3Files/${importSourceId}/${extention}`);
    },

    getAdServerCampaigns: function (dealerId: number): Promise<AxiosResponse<FtCampaign[]>> {
        return axios.get('/api/adServer/' + dealerId + '/campaigns');
    },

    getAdServerPlacements: function (dealerId: number, campaignId: number): Promise<AxiosResponse<FtPlacement[]>> {
        return axios.get(`/api/adServer/${dealerId}/${campaignId}/placements`);
    },

    /**
     * Polk Sales and Audiences
     */
    getPolkDealerDealers(dealerId: number): Promise<AxiosResponse<PolkDealerDealer[]>> {
        return axios.get('/api/polkSales/polkDealerDealers/' + dealerId);
    },

    updatePolkDealerDealers(dealerId: number, polkDealerDealers: PolkDealerDealer[]): Promise<AxiosResponse> {
        return axios.post('/api/polkSales/polkDealerDealers/' + dealerId, polkDealerDealers);
    },

    participatePolkDealerDealers(dealerId: number): Promise<AxiosResponse> {
        return axios.post(`/api/polkSales/polkDealerDealers/${dealerId}/participate`);
    },

    searchPolkDealer(dealerSearch: PolkDealerSearch): Promise<AxiosResponse<PolkDealer[]>> {
        return axios.post('/api/polkSales/dealers', dealerSearch);
    },

    runAttribution(attributionRequest: { date: Date; dealerId: number }): Promise<AxiosResponse> {
        return axios.post('/api/polkSales/runAttribution', attributionRequest);
    },

    getAttributionStatus(dealerId: number): Promise<AxiosResponse<AttributionStatus>> {
        return axios.get('/api/polkSales/' + dealerId + '/attributionStatus');
    },

    getPolkSalesData(amcUploadId: number): Promise<AxiosResponse> {
        return axios.get('/api/polkSales/download/' + amcUploadId);
    },

    getDealerPolkAudienceData(): Promise<AxiosResponse> {
        return axios.get('/api/polkAudience/dealers');
    },

    getDealerPolkAudienceQueueData(): Promise<AxiosResponse> {
        return axios.get('/api/polkAudience/queue');
    },

    getAllAdGroupDicts(): Promise<AxiosResponse> {
        return axios.get('/api/dspAdGroupDict/getAllAdGroupDicts');
    },

    updateBulk(data: any): Promise<AxiosResponse> {
        return axios.patch('/api/dspAdGroupDict/bulkUpdate', data);
    },
    updateAdGroupDict(data: DspAdGroupDict): Promise<AxiosResponse> {
        return axios.put('/api/dspAdGroupDict/' + data.id, data);
    },
};

export default ApiService;

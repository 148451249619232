import { Dealer } from './Dealer';
import { DspEntity } from './DspEntity';
import { User } from './User';

export interface CampaignStatus {
    dspStatusId: string;
    internalStatus?: string;
    externalStatus?: string;
}

export interface CampaignFlight {
    id: number;
    startDateTime: Date;
    endDateTime: Date;
    flightId: string;
    budgetAmount: number;
    totalImpressions: number;
    totalCost: number;
    dspCost: number;
    dspBudget: number;
}

export interface AdServerPlacement {
    id: number;
    fileNameKey: string;
    libraryId: number;
    importSourceId: number;
    adServerCampaignId: number;
    adServerCampaignName: string;
    placementId: number;
    placementName: string;
    creativeId: number;
    fileName: string;
    status: string;
    createdDate: Date;
    updatedDate: Date;
}

export enum AdServerPlacementStatus {
    CONNECTED = 'CONNECTED',
    UPLOADING = 'UPLOADING',
    TRANSCODING = 'TRANSCODING',
    UNSUBMITTED = 'UNSUBMITTED',
    APPROVED = 'APPROVED',
    REPLACED = 'REPLACED',
}

export interface CampaignDataSet {
    id?: number;
    dataSetName: string;
    audienceTargeting: string;
    identifiedRecords: number;
}

export class CampaignFee {
    agencyFee: number = 0;
    agencyFixedCPM: number | null = null;
    agencyIncrementalCPM: number | null = null;
    cognitionCPM: number | null = null;
    displayAgencyFee: number | null = null;
    videoAgencyFee: number | null = null;
    audioAgencyFee: number | null = null;
    audioFixedCPM: number | null = null;
    videoFixedCPM: number | null = null;
    displayFixedCPM: number | null = null;
    displayIncrementalCPM: number | null = null;
    audioIncrementalCPM: number | null = null;
    videoIncrementalCPM: number | null = null;

    cdPlatformFee: number = 0;
    includeCdPlatformFee: boolean = true;
    amazonPassthroughUpcharge: number = 0;
    includeAmazonPassthroughUpcharge: boolean = true;
    cdManagedService: number = 0;
    otherFee: number = 0;
    includeOtherFee: boolean = false;
    otherFeeLabel: string = '';
}

export class BaseCampaign {
    id?: number;
    createdDate: Date = new Date();
    createdByUser: User | null = null;
    startDate: Date | null = null;
    endDate: Date | null = null;
    dealer?: Dealer;
    dealerId?: number;
    budget: number = 0;
    dspBudget: number = 0;
    impressionGoal: number | null = 0;
    impressionBased: boolean = false;
    zipcodes: string = '';
    zipcodeRadius: number = 30;
    geographyTargeting?: string | null;
    audienceDemographic?: boolean;
    audienceLifeStageEvents?: boolean;
    audienceTargeting?: string;
    inventoryProvider?: string;
    creativeAssetLink?: string;
    emailForApproval?: string;
    additionalComments?: string;
    landingPage?: string;
    callToActions?: string;
}

export class Campaign extends BaseCampaign {
    agencyId?: number;
    campaignName?: string;
    dspCampaignId?: string;
    poNumber?: string;
    dspEntityId?: number;
    dspEntity?: DspEntity;
    platform?: string;
    deliveryStatusId: string = 'PENDING_COGNITION';
    deliveryStatus: CampaignStatus = { dspStatusId: 'PENDING_COGNITION' };
    deliveryStatusDate?: Date;
    activationStatusId: string = 'INACTIVE';
    activationStatus?: CampaignStatus;
    activationStatusDate?: Date;
    mediaType?: string;
    campaignType?: string;
    strategyType?: string;

    hasUpdates: boolean = false;

    totalSpend: number = 0;
    totalImpressions: number = 0;
    budgetPacing?: number;
    impressionPacing?: number;

    population: number = 0;
    availableReach: number = 0;
    availableImpressions: number = 0;

    adGroups: CampaignAdGroup[] = [];
    flights: CampaignFlight[] = [];
    adServerPlacements: AdServerPlacement[] = [];
    dataSets: CampaignDataSet[] = [];

    campaignFee: CampaignFee = new CampaignFee();

    campaignId?: number;
    changeRequestId?: number;

    constructor(dealerId: number | undefined) {
        super();
        this.dealerId = dealerId;
    }
}

export class CampaignChangeRequest extends Campaign {
    status: string | null = null;
    statusDate: Date | null = null;

    constructor(dealerId: number | undefined) {
        super(dealerId);
        const date = new Date();
        this.poNumber =
            'CD' +
            date.getUTCFullYear() +
            String(date.getMonth() + 1).padStart(2, '0') +
            String(date.getDate()).padStart(2, '0') +
            String(date.getHours()).padStart(2, '0') +
            String(date.getMinutes()).padStart(2, '0') +
            String(date.getSeconds()).padStart(2, '0');
    }
}

export class ForecastingParameters {
    usPopulation: number = 1;
    primeMembers: number = 1;
    reachBroad: number = 100000;
    reachNarrow: number = 0;
    frequencyValue: number = 3;
    frequencyInterval: number = 30;
}

export interface CpmValue {
    mediaType: string;
    cpmValue: number;
    fixedCpm: boolean;
}

export interface CampaignCriteria {
    [key: string]: any;
    agencyId?: number;
    dealerIds?: number[] | null;
    includePendingCampaigns?: boolean;
    startDate: Date;
    endDate: Date;
    searchText: string;
    deliveryStatusId: string;
    page: number;
    pageSize: number;
    sort: string;
}

export interface CampaignStatusCount {
    statusId: string;
    description: string;
    count: number;
}

export interface ValidateDspCampaignResponse {
    duplicate: boolean;
    campaign: Campaign;
}

export interface GeoLocation {
    id: number;
    category: string;
    geoId: string;
    name: string;
    zipcode: string;
}

export interface CampaignAdGroup {
    id?: number;
    campaignId?: number;
    name?: string;
    state?: string;
    dspAdGroupId?: string;
    inventoryType?: string;
    startDateTime?: Date;
    creationDateTime?: Date;
    lastUpdatedDateTime?: Date;
    createdDate?: Date;
    updatedDate?: Date;
    zipcodes?: string;

    adGroupAudienceSources?: CampaignAdGroupAudienceSource[];
    adGroupInventorySources?: CampaignAdGroupInventorySource[];
    adGroupProductCategories?: CampaignAdGroupProductCategory[];
}

export interface CampaignAdGroupAudienceSource {
    id?: number;
    campaignAdGroupId?: number;
    dspAudienceId: string;
    createdDate?: Date;
    updatedDate?: Date;
}

export interface CampaignAdGroupInventorySource {
    id?: number;
    campaignAdGroupId?: number;
    dspInventoryId: string;
    createdDate?: Date;
    updatedDate?: Date;
}

export interface CampaignAdGroupProductCategory {
    id?: number;
    campaignAdGroupId?: number;
    dspCategoryId: string;
    createdDate?: Date;
    updatedDate?: Date;
}
